import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 203.71px;
  height: 39.44px;
  
        .cls-1 {
        fill: #fff;
      }
`;

const FacebookLogo = () => {
    return (

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 203.71801 39.44114">
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g id="Group_676" data-name="Group 676">
        <path id="Path_6034" data-name="Path 6034" class="cls-1" d="M15.938,6.17712c-2.4,0-3.093,1.066-3.093,3.411v3.891h6.4l-.645,6.298H12.845v19.084H5.169v-19.084H0v-6.292H5.169v-3.785c0-6.341,2.559-9.7,9.7-9.7a36.28875,36.28875,0,0,1,4.477.265v5.912Z"/>
        <path id="Path_6035" data-name="Path 6035" class="cls-1" d="M19.776,25.37013c0-7.09,3.358-12.421,10.395-12.421a7.541,7.541,0,0,1,7.3,4.424v-3.891h7.358v25.372H37.471v-3.834c-1.066,2.45-3.464,4.368-7.3,4.368-7.037,0-10.395-5.328-10.395-12.418Zm7.676,1.706c0,3.785,1.387,6.289,4.957,6.289,3.146,0,4.746-2.292,4.746-5.914v-2.56c0-3.626-1.6-5.918-4.746-5.918-3.57,0-4.957,2.506-4.957,6.292Z"/>
        <path id="Path_6036" data-name="Path 6036" class="cls-1" d="M60.818,12.94912c2.984,0,5.811.639,7.355,1.706l-1.705,5.437a12.082,12.082,0,0,0-5.114-1.172c-4.159,0-5.971,2.4-5.971,6.5v1.49c0,4.106,1.811,6.5,5.971,6.5a12.074,12.074,0,0,0,5.116-1.172l1.705,5.437c-1.543,1.066-4.371,1.706-7.355,1.706-9.007,0-13.113-4.852-13.113-12.633v-1.172c0-7.782,4.106-12.633,13.113-12.633"/>
        <path id="Path_6037" data-name="Path 6037" class="cls-1" d="M69.186,27.12913v-2.239c0-7.2,4.1-11.941,12.474-11.941,7.888,0,11.352,4.8,11.352,11.835v4.05H76.862c.159,3.467,1.705,5.01,5.97,5.01a21.85011,21.85011,0,0,0,8.156-1.543l1.384,5.275c-2.023,1.066-6.182,1.865-9.861,1.865-9.7,0-13.325-4.848-13.325-12.312m7.676-3.305h9.275v-.639c0-2.771-1.119-4.957-4.477-4.957-3.467,0-4.8,2.186-4.8,5.6"/>
        <path id="Path_6038" data-name="Path 6038" class="cls-1" d="M121.477,26.96712c0,7.089-3.411,12.421-10.447,12.421-3.838,0-6.5-1.921-7.57-4.371v3.838H96.211V.74013l7.676-.692v16.951a7.657,7.657,0,0,1,7.143-4.05c7.036,0,10.447,5.328,10.447,12.418Zm-7.676-1.759c0-3.573-1.387-6.235-5.063-6.235-3.146,0-4.851,2.238-4.851,5.861v2.666c0,3.626,1.706,5.865,4.851,5.865,3.676,0,5.063-2.666,5.063-6.239Z"/>
        <path id="Path_6039" data-name="Path 6039" class="cls-1" d="M124.034,26.80712v-1.278c0-7.3,4.156-12.58,12.633-12.58s12.633,5.278,12.633,12.58v1.278c0,7.3-4.159,12.58-12.633,12.58s-12.633-5.275-12.633-12.58m17.586-1.811c0-3.358-1.387-6.023-4.957-6.023s-4.957,2.665-4.957,6.023v2.345c0,3.358,1.384,6.024,4.957,6.024s4.957-2.667,4.957-6.024Z"/>
        <path id="Path_6040" data-name="Path 6040" class="cls-1" d="M151.857,26.80712v-1.278c0-7.3,4.156-12.58,12.633-12.58s12.633,5.278,12.633,12.58v1.278c0,7.3-4.159,12.58-12.633,12.58s-12.633-5.275-12.633-12.58m17.591-1.812c0-3.358-1.387-6.023-4.957-6.023s-4.957,2.665-4.957,6.023v2.345c0,3.358,1.384,6.024,4.957,6.024s4.957-2.666,4.957-6.024Z"/>
        <path id="Path_6041" data-name="Path 6041" class="cls-1" d="M187.674,25.58213l7.57-12.1H203.4l-7.944,12.527,8.262,12.845h-8.153l-7.891-12.418v12.418h-7.676V.74312l7.676-.7Z"/>
      </g>
    </g>
  </g>
</Logo>

    )
}
export default FacebookLogo