import React from "react";
import styled from "styled-components"

const Logo = styled.svg `
  width: 300px;
  height: 117.66px;
  
  .cls-1 {
        fill: #0093aa;
      }

      .cls-2 {
        fill: #fff;
      }
`;

const TvmhcFooterLogo = () => {
    return (

<Logo xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 117.66069">
  <defs>
    <style>

    </style>
  </defs>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls-1" d="M111.05632,36.13425c2.96687-2.10153,3.79519-4.93962,3.56594-8.23711a9.756,9.756,0,0,1-.03619-1.89428c.2886-2.2555.47374-2.31092,2.71415-1.869,1.73939.34311,3.51605.4934,5.27095.76319,1.24911.192,1.80671.9406,1.38977,2.16272-.86122,2.52441.15408,4.31651,2.14825,5.72723,3.42832,2.42529,5.493,5.92322,7.54635,9.44328a41.7201,41.7201,0,0,0,3.1063,5.03142c4.57493,5.76224,5.66621,12.77862,7.26727,19.61541.71955,3.07259.69884,3.24166-2.42773,3.80108a40.74126,40.74126,0,0,1-6.76288.36818,2.32944,2.32944,0,0,1-1.65586-.77722c-1.13419-1.49272-2.33967-1.26107-3.82858-.60655q-10.55019,4.63791-21.15937,9.14126c-1.45249.62054-2.96712,1.09555-4.82592,1.77363.2653-5.12076-1.2626-10.14207,3.18037-14.33038,4.24255-3.99936,8.0465-8.47165,11.94956-12.82014,1.88349-2.09846,1.26166-4.29867-1.2261-4.74564a2.7413,2.7413,0,0,0-1.9545.364c-2.247,1.74445-4.49769,3.50776-6.55708,5.46432-2.43167,2.31026-4.65178,4.842-6.99994,7.24211-.6921.70742-1.50734,1.29434-2.3356,1.99526.34747-1.79292.75961-3.62322,1.03756-5.47368a10.49191,10.49191,0,0,0,.05257-3.00012c-.2097-1.46845-.568-2.96225-2.50939-3.06251a4.27541,4.27541,0,0,0-3.77405,2.98067c-.44918,1.58713-.82921,3.19382-1.2975,5.01731-3.17532-2.6442-6.948-4.408-8.98508-8.151a2.639,2.639,0,0,0-.55442-.7729C79.823,48.28564,78.90165,44.27763,79.06732,39.874a2.09331,2.09331,0,0,1,.52855-1.46751c6.11119-4.33487,12.27406-8.59681,18.39658-12.91587,1.188-.838,2.01112-.55122,2.92718.41835,2.914,3.08417,5.87616,6.12287,8.83311,9.16614A15.02913,15.02913,0,0,0,111.05632,36.13425Z"/>
        <path class="cls-1" d="M218.9966,62.15993a33.99572,33.99572,0,0,0-.518-4.93008c-.405-1.60245-1.3647-2.87222-3.36207-2.75424-1.9923.11768-2.50962,1.53482-2.71585,3.10827-.38312,2.92342-.65014,5.862-.91981,8.36956-3.67288-3.96912-7.74606-8.4026-11.86094-12.797-1.14324-1.22087-2.21984-2.69316-4.32585-1.68617-1.47113.7034-1.99577,1.658-1.10571,2.98382,2.90073,4.3209,5.84035,8.61846,8.88113,12.84138,1.48236,2.05864,3.23781,3.91931,4.8315,5.90029a2.46046,2.46046,0,0,1,.59283,1.3408c.05707,2.51269.0273,5.02736.0273,7.5947-7.62472-3.844-15.07955-7.62106-22.56825-11.32971-.43-.213-1.15591.06427-1.71026.22794a62.58148,62.58148,0,0,1-7.63919,2.2588c-4.4828.71522-6.5492-1.08077-6.1269-5.53768a41.49044,41.49044,0,0,1,9.49035-23.46507c2.62749-3.107,5.45465-6.04726,8.03246-9.19325,1.45659-1.77762,3.20425-3.66645,1.58852-6.3079a.9162.9162,0,0,1-.05184-.55507c.181-1.31795,2.95214-2.81593,5.06529-2.74794,1.61977.05211,2.31278,1.00374,2.53546,2.9531.29338,2.56836.72007,5.12549,1.20663,7.665a4.00664,4.00664,0,0,0,1.071,1.75808c1.57815,1.719,2.784,1.72674,4.26233-.07678,1.36587-1.66633,2.513-3.51267,3.88774-5.17086,1.37768-1.66169,2.91779-3.18869,4.483-4.87869A43.1971,43.1971,0,0,1,225.41,37.72388c1.5781,1.64639,3.13867,3.32245,4.84265,4.83214,2.20425,1.95293,2.74187,5.59268.71615,7.7082-2.98412,3.11643-6.17324,6.03609-9.25856,9.0564C220.8173,60.19476,219.98081,61.1266,218.9966,62.15993Z"/>
        <path class="cls-1" d="M53.4625,88.28174a51.61887,51.61887,0,0,1,.82269-6.50241A9.84162,9.84162,0,0,1,56.346,78.02621c2.00306-2.35673,4.15339-4.59593,6.34032-6.78656,2.10645-2.11,4.35667-4.07594,6.49009-6.16006.41413-.40456.9372-1.39175.79755-1.54239-.46949-.50642-1.26273-1.11192-1.84658-1.03451a10.94154,10.94154,0,0,0-4.2674,1.20014A35.00947,35.00947,0,0,0,53.45961,74.191a2.17174,2.17174,0,0,1-1.37381.882c.11791-1.364.27229-2.7261.34472-4.09252.09636-1.81788.21713-3.642.15754-5.45792-.03919-1.19457-.90945-1.5461-2.04122-1.38879-2.78465.387-4.63259,2.57772-4.873,5.98721a3.56817,3.56817,0,0,1-.90733-.58242c-3.49114-4.45988-6.94883-8.946-10.44669-13.40057-.87767-1.11772-.68375-1.87806.46822-2.64082,5.45405-3.61129,10.883-7.26054,16.32146-10.89533.52488-.3508,1.05408-.69514,1.72621-1.138l9.97335,9.34729c.37284-.71386.80291-1.44428,1.14661-2.21329.92773-2.07568,1.79383-4.17907,2.734-6.24894.52356-1.1527,1.34583-1.31469,2.64219-1.07369,2.33846.43472,3.39477,1.49148,4.05947,3.91765a30.519,30.519,0,0,0,8.2774,13.51725,14.78243,14.78243,0,0,1,3.03759,4.28014,39.66381,39.66381,0,0,1,3.09076,16.49482c-3.17185.72649-6.20574,1.77067-9.38552-.26406-.75081-.48044-2.34148-.04258-3.37742.38287-5.353,2.1984-10.63656,4.56513-15.977,6.795C57.33238,87.11906,55.50843,87.60162,53.4625,88.28174Z"/>
        <path class="cls-1" d="M162.32664,45.729c.11349-1.32121.32108-2.64232.32274-3.96367.00488-3.88924-2.58784-6.76871-5.98491-6.7496-3.61857.02035-6.2866,3.01948-6.2699,6.95919a24.472,24.472,0,0,1-.33749,2.972l-.60158.0802q-6.95208-8.43345-13.9-16.87032a14.54608,14.54608,0,0,0-1.37941-1.82209c-2.633-2.30769-3.02381-5.23014-1.93314-8.258a8.05826,8.05826,0,0,1,2.89831-3.35759c6.681-4.85938,13.44654-9.60245,20.18927-14.3767A4.714,4.714,0,0,1,156.21022,0l21.7603,31.56834L162.30207,45.70255Z"/>
        <path class="cls-1" d="M254.21776,47.01426c3.05436,2.30761,6.15261,4.42081,8.97752,6.85166a85.11426,85.11426,0,0,1,6.80968,6.90476,2.13161,2.13161,0,0,1,.31457,1.8937,34.77081,34.77081,0,0,1-8.69543,11.98409,15.25383,15.25383,0,0,0-.658-2.44071,5.381,5.381,0,0,0-1.49146-2.28578c-.92336-.61482-2.36878-1.33092-3.16669-.99588-.77376.32491-1.2169,1.88334-1.43734,2.97438a44.95008,44.95008,0,0,0-.46935,5.06582c-3.29388-2.85183-6.43927-5.74631-9.78816-8.38225a11.2929,11.2929,0,0,0-4.41731-1.596c.977,3.69453,3.1574,6.39907,5.46768,9.01312,1.59938,1.80968,3.31727,3.52053,4.82786,5.39965a7.06982,7.06982,0,0,1,.96025,2.62c.44872,1.70741.81952,3.4353,1.16218,4.89659-3.29356-1.1232-6.57965-2.17407-9.81242-3.36912-2.43857-.90145-4.82986-1.94077-7.20328-3.00543-2.04735-.91838-3.91528-1.93768-6.05748.05708a3.66067,3.66067,0,0,1-4.4428.35408,13.51366,13.51366,0,0,0-3.10142-.81078c.13156-1.15465.19937-2.3413.41115-3.50167.75348-4.1285,1.63393-8.23538,2.30694-12.37634.33533-2.06323,1.65486-3.37422,2.95472-4.75361,2.70451-2.87,5.45016-5.70161,8.12874-8.59541a2.47192,2.47192,0,0,0,.69182-1.64484c-.23153-2.57327.04872-3.18724,2.40282-4.39406.5028-.25778,1.01515-.49689,1.41216-.6905,1.14212,2.74762,2.1296,5.48547,3.4002,8.08493,1.4734,3.01443,3.28607,3.21371,5.52821.67756a20.482,20.482,0,0,0,2.10169-3.3785c.57386-.988,1.06255-2.0262,1.65263-3.00374A15.45494,15.45494,0,0,1,254.21776,47.01426Z"/>
        <path class="cls-1" d="M41.79958,85.405a12.47622,12.47622,0,0,1-7.0568-.17467c-4.39988-1.451-8.9457-2.45319-13.40183-3.74221-2.7396-.79249-5.41859-1.79228-8.13752-2.65907-3.02264-.96362-6.04041-1.95127-9.09538-2.80258C.16761,74.92835-1.43843,69.95328,1.53786,67.131c1.06539-1.01026,3.02227-1.11681,4.59643-1.54748,5.31672-1.45459,10.79836-1.56062,16.24631-1.79064a60.94439,60.94439,0,0,1,8.66889.36572,4.81131,4.81131,0,0,1,2.705,1.468c2.15018,2.25671,4.165,4.64486,6.17862,7.02739a4.035,4.035,0,0,1,1.05737,1.96189C41.33337,78.14873,41.536,81.69523,41.79958,85.405Z"/>
        <path class="cls-1" d="M300,73.60419a10.53633,10.53633,0,0,0-.44522,2.12473c.128,6.9188.35135,6.8065-6.40469,8.324-6.563,1.47413-13.17872,2.7125-19.75027,4.15037-1.97707.43258-3.87251,1.22674-5.82782,1.77516a28.6074,28.6074,0,0,1-2.9624.51469,15.06646,15.06646,0,0,1,.83154-8.46676c.9998-2.74034,2.14073-5.43917,3.38586-8.07786,2.31609-4.90829,6.66371-6.05881,11.59223-5.99289,6.4862.08675,12.4222,2.2619,18.24313,4.85106A10.485,10.485,0,0,1,300,73.60419Z"/>
        <path class="cls-2" d="M146.1578,116.39694c.80623-13.3857,3.165-26.47626,5.38814-40.06362l10.46079.51259c1.473,13.7421,2.90888,27.13724,4.35895,40.66517h-6.66214c-.14348-2.042-.32865-4.0355-.4156-6.03332-.27889-6.40877-.49428-12.82039-.79246-19.22819-.10808-2.32258-1.03672-3.02645-2.78463-2.17863a2.054,2.054,0,0,0-.91856,1.4108c-.64875,5.41106-1.26025,10.8273-1.80273,16.25007-.27117,2.71066-.30436,5.44654-.62625,8.14946a2.027,2.027,0,0,1-1.4878,1.31108A35.636,35.636,0,0,1,146.1578,116.39694Z"/>
        <path class="cls-2" d="M101.08934,85.41934c.23087,10.289.45731,20.3808.68154,30.37417l-5.37318,1.53433c0-6.00734-.00426-12.16469.00312-18.322.00177-1.46307.126-2.93205.02193-4.386-.03856-.53872-.51829-1.4206-.89073-1.47255a2.07022,2.07022,0,0,0-1.73279.74533,6.22787,6.22787,0,0,0-.83882,2.80186c-.22657,5.716-.35148,11.436-.52394,17.1542-.005.16634-.15945.32819-.26643.5351-1.30667-.15574-2.60038-.30992-4.0584-.48368.24272-2.837.513-5.772.74107-8.71026.42323-5.45233.8483-10.9048,1.21084-16.36131A2.43013,2.43013,0,0,1,91.844,86.47927,15.57122,15.57122,0,0,1,101.08934,85.41934Z"/>
        <path class="cls-2" d="M222.46075,117.24076l-5.66063.41993c0-1.70965.01741-3.46-.00345-5.20982-.0515-4.32163-.05883-8.64564-.24088-12.96208-.03178-.75349-.34429-2.21083-1.27233-2.21083s-1.62413,1.40464-1.68216,2.16455c-.30892,4.04524-.36479,8.10953-.52432,12.16659-.07066,1.79714-.17916,3.59279-.27815,5.53959-1.995.9611-3.52363.1799-3.405-2.22244.40984-8.301.92567-16.59692,1.4224-24.89349.02052-.34224.19347-.91756.39986-.96953,2.44039-.61448,4.89109-1.20537,7.36814-1.63476.30468-.05281,1.01429.81359,1.13757,1.3463a93.03008,93.03008,0,0,1,1.94663,19.47746C221.72085,111.27388,222.18781,114.28835,222.46075,117.24076Z"/>
        <path class="cls-2" d="M51.27364,92.72867v20.73116l-2.2014.32422V99.06676c-2.11837.24249-3.04229,1.04726-3.18164,3.11678-.20844,3.09539-.84767,6.16079-1.277,9.24267-.26372,1.89315-.24141,1.89625-1.79968,1.5153.72919-6.53092,1.4899-13.0031,2.14482-19.486.13754-1.36147.62359-1.82016,1.93141-1.54685C48.30721,92.20485,49.73626,92.44376,51.27364,92.72867Z"/>
        <path class="cls-2" d="M260.32839,116.04775c-.71594-4.53726-.65092-13.63537-1.5416-13.63537s-.88675,8.05145-1.47792,12.03c-1.69211.25427-2.37585-.09639-2.15072-2.00094.64678-5.47112,1.06255-10.96954,1.58307-16.6084l4.40774-.53807c.18241,2.21732.26506,4.297.54112,6.35082.5478,4.07543,1.18279,8.1396,1.8268,12.20129.1537.96941.06454,1.61585-1.0881,1.74383A20.37077,20.37077,0,0,0,260.32839,116.04775Z"/>
        <path class="cls-1" d="M162.30207,45.70255l-.14219.26031q.08328-.117.16667-.234Z"/>
      </g>
    </g>
  </g>
</Logo>
    )
}
export default TvmhcFooterLogo
