import React from 'react';
import { Link } from 'gatsby'
import { Button } from "react-bootstrap"
import { slide as Menu } from "react-burger-menu"
import "./burgerMenu.css"

const BurgerMenu = () => {
        return (
            <>
            <Menu right>
            <Link to="/counselors-programs">Counselors &amp; Programs</Link>
            <hr />
            <Link to="/resources">Resources</Link>
            <hr />
            <Link to="/about">About Us</Link>
            <hr />
            <Link to="/donate#contact">Contact</Link>
            <hr />
            <Link to="/blog">News</Link>
            <hr />
            <Link to="/donate"><Button className="navDonateBtn btn-block">DONATE</Button></Link>
            </Menu>

            {/* <Menu right>
                <Link to="/about" className="menu-item">About</Link>
                <Link to="/programs-events" className="menu-item">Programs &amp; Events</Link>
                <Link to="/the-project" className="menu-item">The Project</Link>
                <Link to="/connect" className="menu-item">Connect</Link>
                <Link
                    className="navbar-link"
                    to="/donate/"
                    activeClassName="active"
                    style={{
                        marginTop: '20px',
                    }}
                  >
                    <Button>Donate</Button>
                  </Link>
            </Menu> */}
            </>
        )
    }

export default BurgerMenu;