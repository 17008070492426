import React from 'react'
import styled from 'styled-components'
import CommunityFoundationLogo from './community-foundation-logo.svg'
import IdahoCommunityFoundationLogo from './idahoCommunityFoundation.svg'
import SilverStarLogo from './SilverStarLogo.svg'
import TetonCountyLogo from './TetonCountyLogo.svg'
import TetonSpringsFoundationLogo from './TetonSpringsFoundationLogo.svg'

const PartnersGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    align-items: center;
    justify-items: center;

    & > div {
        width: 180px;
    }

    #tetonSprings {
        grid-column: span 2;
        justify-self: center; 
        width: 180px;
    }

    @media(min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }
`;



export default function Partners() {

    return (
        <PartnersGrid>
            <div>
            <a href="https://cftetonvalley.org" target="_blank" target="noopener noreferrer">
                <img src={CommunityFoundationLogo} alt="Community Foundation of Teton Valley" />
            </a>
            </div>
            <div>
            <a href="https://www.idahocf.org/home" target="_blank" target="noopener noreferrer">
                <img src={IdahoCommunityFoundationLogo} alt="Idaho Community Foundation" />
            </a>
            </div>
            <div>
            <a href="https://www.silverstar.com" target="_blank" target="noopener noreferrer">
                <img src={SilverStarLogo} alt="Silver Star" />
            </a>
            </div>
            <div>
            <a href="https://www.tetoncountyidaho.gov/index.php" target="_blank" target="noopener noreferrer">
                <img src={TetonCountyLogo} alt="Teton County Idaho" />
            </a>
            </div>
            <div id="tetonSprings">
            <a href="http://www.tetonspringsfoundation.org" target="_blank" target="noopener noreferrer">
                <img src={TetonSpringsFoundationLogo} alt="Teton County Idaho" />
            </a>
            </div>
        </PartnersGrid>
    )
}
