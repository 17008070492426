import React from "react"
import { Link, useStaticQuery } from 'gatsby'
import { Row, Col, Media } from "react-bootstrap"
import styled from "styled-components"
import TvmhcFooterLogo from "./TvmhcFooterLogo";
import FacebookLogo from "./FacebookLogo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-regular-svg-icons"
import Partners from "./Supporters/Supporters";

const FooterWrapper = styled.footer`
  background-color: #434081;
  
`;

const FooterContainer = styled.div`
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr 1.25fr;
    grid-gap: 2rem;
    padding: 20px;
    color: white;
    > p {
        font-size: 18px;
    }
    @media(max-width: 1024px) {
      grid-template-columns: 1fr;
      text-align:center;  
    }
`;


const FooterItem = styled.div`

`;

const FooterLinkTitle = styled.h4`
    font-size: 1.5rem;
    font-family: abadi, sans-serif;
  font-weight: 400;
  font-style: normal;
`
const FooterLinks = styled.ul`
    padding-left: 0;
    > li {
        list-style-type: none;
        font-size: 18px;
        margin-bottom: 10px;
    }
    > li > a {
        color: #D8D8F6;
    }
    > li > a:hover {
        color: white;
    }
`;
const SubFooterWrapper = styled.div`
    background-color: #3696AC;
    padding: 20px;
    color: white;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    text-align: center;
    align-items: center;
        

    @media(min-width: 768px) {
        grid-template-columns: 1fr 1fr 1fr;
        > div {
            text-align: left;
        }
        > div:last-child {
            text-align: right;
        }
    }
`;

const FacebookWrapper = styled.div`
    display: flex;
    justify-content: center;    
    text-align: center;
    
    @media(min-width: 768px) {
        text-align: right;
        grid-template-columns: 1fr 1fr;
        > div:last-child {
            text-align: left;
        }
    }
`;

const Footer = () => {
const data = useStaticQuery(graphql`
{
  providerPDF: allMarkdownRemark(filter: {frontmatter: {title: {eq: "Provider List PDF"}}}) {
    edges {
      node {
        frontmatter {
          title
          providerpdf
        }
      }
    }
  }
  allPrismicListOfProviders {
    edges {
      node {
        data {
          link_to_provider_pdf {
            url
          }
          link_label {
            text
          }
        }
      }
    }
  }
}
`
)
    const providerPdf = data.allPrismicListOfProviders.edges[0].node.data

    return (
  <FooterWrapper>
    <FooterContainer>

        <div><div className="text-center">
                <Link to="/"><TvmhcFooterLogo /></Link>
            </div>
            <p style={{marginTop: '20px', color: 'white', fontWeight: 300}}>
            Teton Valley Mental Health Coalition is a nonprofit organization dedicated to promoting mental wellness in Teton Valley. <strong style={{color: '#3696AC'}}>We are not a direct provider of mental health services. If you or a loved one are experiencing a psychiatric emergency, please call 911 or go to the nearest emergency room.</strong> To make an appointment with a local mental health counselor, please contact the provider of your choice directly.
            </p></div>
        
        <div>
        <FooterLinkTitle>Menu</FooterLinkTitle>
                <FooterLinks>
                    <li>
                       <Link to="/counselors-programs">Counseling &amp; Programs</Link>
                    </li>
                    <li>
                       <Link to="/resources">Resources</Link>
                    </li>
                    <li>
                       <Link to="/about">About Us</Link>
                    </li>
                    <li>
                       <Link to="/donate#contact">Contact</Link>
                    </li>
                    <li>
                       <Link to="/news">News</Link>
                    </li>
                    <li>
                       <Link to="/donate">Donate</Link>
                    </li>
                    <li>
                       <a href={`${providerPdf.link_to_provider_pdf.url}`} target="_blank">{providerPdf.link_label.text}{' '}<FontAwesomeIcon icon={faFilePdf} /></a>
                    </li>
                </FooterLinks>
        </div>
        
        <div><FooterLinkTitle>Helpful Links</FooterLinkTitle>
                <FooterLinks>
                    <li>
                       <a href="https://crctv.org/" target="_blank" rel="noopener noreferrer">Community Resource Center of Teton Valley</a>
                    </li>
                    <li>
                       <a href="https://www.cdc.gov/mentalhealth/tools-resources/index.htm" target="_blank" rel="noopener noreferrer">Mental Health Tools &amp; Resources</a>
                    </li>
                    <li>
                       <a href="https://idahoarea18aa.org/meetings?tsml-day=any&tsml-region=driggs " target="_blank" rel="noopener noreferrer">Alcoholics Anonymous</a>
                    </li>
                    <li>
                       <a href="https://suicidepreventionlifeline.org/" target="_blank" rel="noopener noreferrer">National Suicide Prevention Lifeline</a>
                    </li>
                    <li>
                       <a href="https://www.counseling.org/knowledge-center/mental-health-resources/grief-and-loss-resources#" target="_blank" rel="noopener noreferrer">Grief and Loss Resources</a>
                    </li>
                    <li>
                       <a href="https://www.nami.org/Home" target="_blank" rel="noopener noreferrer">National Alliance on Mental Illness (NAMI)</a>
                    </li>
                </FooterLinks></div>
        
        <div>
        <div>
          <FooterLinkTitle>Partners</FooterLinkTitle>
          <Partners />
        </div>
        
        </div>
    </FooterContainer>
    <SubFooterWrapper>
    
        <div>
          © {new Date().getFullYear()}, Teton Valley Mental Health Coalition | <Link to="/privacy">Privacy Policy</Link>
        </div>
        <div>
        <FacebookWrapper>
            <div style={{marginRight: '10px'}}>FOLLOW US ON</div>
              <div><a href="https://facebook.com/Tvmhc" target="_blank" rel="noopener noreferrer"><FacebookLogo /></a></div>
              </FacebookWrapper>
          </div>
        <div>
        Website by LGV Creative and{" "}
          <a
            href="https://inlightmedia.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Inlight Media
          </a>
        </div>
    
    </SubFooterWrapper>
  </FooterWrapper>
  )
}

export default Footer
