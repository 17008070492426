import React from "react"
import { Link } from "gatsby"
import TVMHCLogo from "./TVMHCLogo"
import { Navbar, Nav, Button } from "react-bootstrap"
import TVMHCLogoSm from "./TVMHCLogoSm"
import BurgerMenu from "./BurgerMenu"

const Header = () => (
  <header>
    <div style={{
      background: '#3696AC',
      padding: '5px'
    }}>
      <h2 className="text-center preHeader">We are not a direct service provider. If you are having a mental health emergency, please dial 911.</h2>
    </div>
    <Navbar expand="lg" bg="light">
      <Navbar.Brand>
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {/* <TVMHCLogo /> */}
          <TVMHCLogoSm />
        </Link>
      </Navbar.Brand>
      <div id="burgerMenu" >
      <BurgerMenu />
      </div>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto" style={{ width: "100%" }} fill>
          <Nav.Item>
            <Link to="/counselors-programs">Counselors &amp; Programs</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/resources">Resources</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/about">About Us</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/donate#contact">Contact</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/blog">News</Link>
          </Nav.Item>
         </Nav>

      <Link to="/donate"><Button className="navDonateBtn">DONATE</Button></Link>
      </Navbar.Collapse>
    </Navbar>

  </header>
)

export default Header
